var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"activity-wrapper"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"line_wrapper cursorP loads",attrs:{"element-loading-spinner":"el-icon-loading","element-loading-text":"加载中.."}},_vm._l((_vm.outlineList),function(item){return _c('div',{key:item.index,staticClass:"line_box",on:{"click":function($event){return _vm.goRouter(
          '/activity_outline/activity_details',
          item.id,
          item.activityName
        )}}},[_c('div',{staticClass:"imgs"},[_c('img',{staticClass:"err_image",attrs:{"src":item.imgUrl,"alt":""}})]),_c('div',{staticClass:"contents"},[_c('div',{staticClass:"content_top"},[_c('p',[_vm._v(_vm._s(item.activityName))]),_c('div',[_c('div',{staticClass:"logos",class:[
                item.isGetOn == '1' ? 'going' : '',
                item.isGetOn == '2' ? 'ending' : '',
                item.isGetOn == '3' ? 'oning' : '',
                item.isGetOn == '4' ? 'ending' : '',
              ]},[_vm._v(" "+_vm._s(_vm._f("getIsGetOns")(item.isGetOn,item.isGetOn))+" ")])])]),_c('div',{staticClass:"content_foot"},[_vm._v(" 活动时间： "),_c('span',{class:item.isGetOn == '1' ? 'color-grey' : ''},[_vm._v(_vm._s(item.activityStartTime)+" 至 "+_vm._s(item.activityEndTime))])])]),_c('div',{staticClass:"address"},[_vm._m(0,true),_c('p',{staticClass:"p1 line_clamp1"},[_vm._v(_vm._s(item.activityArea)+_vm._s(item.activityAddress))]),_c('p',{staticClass:"p2"},[_vm._v(_vm._s(item.personCount)+"人已报名")])])])}),0),(_vm.outlineList == null || _vm.outlineList.length <= 0)?_c('status',{attrs:{"type":"post","des":"暂无活动"}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgs"},[_c('img',{attrs:{"src":require("../../static/activity/address.png"),"alt":"图片加载失败"}})])
}]

export { render, staticRenderFns }