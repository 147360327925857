<template>
  <div class="activity-wrapper">
    <div
      class="line_wrapper cursorP loads"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      element-loading-text="加载中.."
    >
      <div
        class="line_box"
        v-for="item in outlineList"
        :key="item.index"
        @click="
          goRouter(
            '/activity_outline/activity_details',
            item.id,
            item.activityName
          )
        "
      >
        <div class="imgs">
          <img :src="item.imgUrl" alt="" class="err_image" />
        </div>
        <div class="contents">
          <div class="content_top">
            <p>{{ item.activityName }}</p>
            <div>
              <div
                class="logos"
                :class="[
                  item.isGetOn == '1' ? 'going' : '',
                  item.isGetOn == '2' ? 'ending' : '',
                  item.isGetOn == '3' ? 'oning' : '',
                  item.isGetOn == '4' ? 'ending' : '',
                ]"
              >
                {{ item.isGetOn | getIsGetOns(item.isGetOn) }}
              </div>
            </div>
          </div>
          <div class="content_foot">
            活动时间：
            <span :class="item.isGetOn == '1' ? 'color-grey' : ''"
              >{{ item.activityStartTime }} 至 {{ item.activityEndTime }}</span
            >
          </div>
        </div>
        <div class="address">
          <div class="imgs">
            <img src="../../static/activity/address.png" alt="图片加载失败" />
          </div>
          <p class="p1 line_clamp1">{{ item.activityArea }}{{ item.activityAddress }}</p>
          <p class="p2">{{ item.personCount }}人已报名</p>
        </div>
      </div>
    </div>
    <status
      type="post"
      des="暂无活动"
      v-if="outlineList == null || outlineList.length <= 0"
    ></status>
  </div>
</template>

<script>
export default {
  name: "outline",
  data() {
    return {
      ongoingList: [{ status: 2 }],
      outlineList: [],
      queryParams: {
        pageNum: 1,
        pageSize: 6,
      },
      loading: true,
    };
  },
  filters: {
    getIsGetOns(key) {
      let isGetOn = "";
      switch (key) {
        case "1":
          isGetOn = "报名中";
          break;
        case "2":
          isGetOn = "未开始";
          break;
        case "3":
          isGetOn = "进行中";
          break;
        case "4":
          isGetOn = "已结束";
          break;
      }
      return isGetOn;
    },
  },
  methods: {
    // 请求活动接口
    async outlineActivityList() {
      this.loading = true;
      let that = this;
      let res = await that.$api.outlineActivityList(that.queryParams);
      console.log(res);
      if (res.data.success) {
        that.outlineList = res.data.data.records;
        this.loading = false;
      }
    },
    goRouter(url, ids, activityName) {
      const { href } = this.$router.resolve({
        path: url,
        query: {
          id: ids,
          activityName: activityName,
        },
      });
      window.open(href);
    },
  },
  created() {
    this.outlineActivityList();
  },
};
</script>

<style lang="less" scoped>
@import "~assets/css/activity.less";
</style>